@import "variables";

@media(max-width: $bp-large) {
  .navbar {
    height: 116px;
  }
  .navbar.navbar-default {
    border-bottom: #513593 5px solid;
  }
  .navbar.checkout .steps {
    width: 100% !important;
  }
  .navbar.checkout .steps ul li {
    width: 25%;
    margin: 0;
  }
  .navbar:after {
    width: 100%;
    height: 5px;
    content: ' ';
    background: #ae1f6f;
    bottom: -10px;
    left: 0;
    position: absolute;
  }
  .navbar .open-menu {
    width: 63px;
    height: 48px;
    padding: 10px;
    margin: 32px 0 0 14px;
    float: left;
  }
  .navbar .open-menu .bar {
    width: 43px;
    height: 4px;
    margin-top: 8px;
    background: #513593;
    display: block;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
  }
  .navbar .open-menu .bar:first-child {
    margin: 0;
  }
  .navbar .open-menu.open .bar:nth-child(1) {
    width: 53px;
    margin: 12px 0 0 -3px;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .navbar .open-menu.open .bar:nth-child(2) {
    width: 53px;
    margin: -4px 0 0 -4px;
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .navbar .open-menu.open .bar:nth-child(3) {
    opacity: 0;
  }
  .navbar .logo {
    height: 33px;
    margin: 44px 0 0 35px;
    float: left;
    position: relative;
  }
  .navbar .right {
    width: auto;
    padding-top: 34px;
    margin: 0 20px 0 0;
  }
  .navbar .right .bag {
    margin: 0;
  }
  .navbar .right .bag .icon {
    width: 36px;
    height: 44px;
    background-image: url("../img/bag-icon-mobile.png");
    float: left;
  }
  .navbar .right .bag .count {
    width: 43px;
    height: 42px;
    line-height: 44px;
    margin: 5px 0 0 17px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    font-size: 25px;
    top: auto;
    left: auto;
    position: relative;
    float: left;
  }
  #menu-mobile {
    width: 100%;
    max-height: 0;
    height: calc(100% - 121px);
    background: #e9e0d7;
    top: 121px;
    left: 0;
    position: fixed;
    overflow: hidden;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
  }
  #menu-mobile.open {
    max-height: 800px;
  }
  #menu-mobile form {
    padding: 50px 46px;
    border-bottom: #d3c3b1 1px solid;
    background: #e9e0d7;
    overflow: hidden;
  }
  #menu-mobile form input {
    width: calc(100% - 68px);
    height: 60px;
    border: 0;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    border-radius: 5px 0 0 5px;
    font-size: 24px;
    background: #fff;
    float: left;
  }
  #menu-mobile form button {
    width: 68px;
    height: 60px;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;
    background: #fff url("../img/lupa-mobile.png");
    float: left;
  }
  #menu-mobile > ul {
    padding: 0;
    margin: 0;
  }
  #menu-mobile > ul > li {
    border-bottom: #d3c3b1 1px solid;
    list-style: none;
  }
  #menu-mobile > ul > li > a {
    line-height: 45px;
    padding: 23px 46px 17px;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 33px;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    position: relative;
  }
  #menu-mobile > ul > li.has-submenu > a:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 12px;
    border-color: transparent transparent transparent #85685b;
    content: '';
    margin-top: -10px;
    top: 50%;
    right: 46px;
    position: absolute;
  }
  #menu-mobile > ul > li.has-submenu .submenu {
    width: 100%;
    height: calc(100% - 121px);
    background: #e9e0d7;
    top: 121px;
    left: 100%;
    position: fixed;
    overflow: auto;
    z-index: 1032;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
  }
  #menu-mobile > ul > li.has-submenu .submenu.open {
    top: 81px;
    left: auto;
    bottom: 100%;
    padding-bottom: 60px;
  }
  #menu-mobile > ul > li.has-submenu .submenu .voltar {
    padding: 30px 90px 24px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 24px;
    color: #4e2413;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    position: relative;
  }
  #menu-mobile > ul > li.has-submenu .submenu .voltar:before {
    width: 0;
    height: 0;
    margin: -20px 0 0 -10px;
    border-style: solid;
    border-width: 20px 20px 20px 0;
    border-color: transparent #4e2413 transparent transparent;
    content: '';
    top: 50%;
    left: 50px;
    position: absolute;
  }
  #menu-mobile > ul > li.has-submenu .submenu .voltar:after {
    width: 0;
    height: 0;
    margin: -20px 0 0 -10px;
    border-style: solid;
    border-width: 20px 20px 20px 0;
    border-color: transparent #e9e0d7 transparent transparent;
    content: '';
    top: 50%;
    left: 52px;
    position: absolute;
  }
  #menu-mobile > ul > li.has-submenu .submenu .link {
    height: 45px;
    line-height: 45px;
    padding: 23px 46px 17px;
    border-top: #d3c3b1 1px solid;
    border-bottom: #d3c3b1 1px solid;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 33px;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    position: relative;
  }
  #menu-mobile > ul > li.has-submenu .submenu .link:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 10px 0 10px;
    border-color: #85685b transparent transparent transparent;
    content: '';
    margin-top: -10px;
    top: 50%;
    right: 46px;
    position: absolute;
  }
  #menu-mobile > ul > li.has-submenu .submenu p {
    padding: 50px 46px 30px;
    margin: 0;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 33px;
    color: #4e2413;
  }
  #menu-mobile > ul > li.has-submenu .submenu ul {
    padding: 0;
    margin: 0;
  }
  #menu-mobile > ul > li.has-submenu .submenu ul li {
    list-style: none;
  }
  #menu-mobile > ul > li.has-submenu .submenu ul li a {
    height: 80px;
    line-height: 40px;
    padding: 24px 80px 16px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 24px;
    color: #888;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    position: relative;
  }
  #menu-mobile > ul > li.has-submenu .submenu ul li a:after {
    width: 40px;
    height: 3px;
    background: #912800;
    content: '';
    margin-top: -1px;
    top: 50%;
    left: 23px;
    position: absolute;
  }
  #menu-mobile > ul > li.has-submenu .submenu ul + p {
    border-top: #fff 1px solid;
  }
  #menu-mobile > ul > li:last-child {
    border: 0;
  }
  #menu-mobile > ul > li.institucional {
    padding: 0;
    border-color: #fff;
  }
  #menu-mobile > ul > li.institucional ul {
    padding: 20px 0;
    margin: 0;
  }
  #menu-mobile > ul > li.institucional ul li {
    list-style: none;
  }
  #menu-mobile > ul > li.institucional ul li a {
    height: 80px;
    line-height: 40px;
    padding: 24px 80px 16px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 24px;
    color: #888;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    position: relative;
  }
  #menu-mobile > ul > li.institucional ul li a:after {
    width: 40px;
    height: 3px;
    background: #912800;
    content: '';
    margin-top: -1px;
    top: 50%;
    left: 23px;
    position: absolute;
  }
}